import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <main>
      <h1 style={{ fontWeight: 'normal' }}>
        <code>404</code> not found :/
      </h1>
      <p>sorry, couldn't find that page</p>
      <p style={{ textAlign: 'center' }}>
        <a href="/">home</a>
      </p>
    </main>
  </Layout>
);

export default NotFoundPage;
